import "./simplePage.scss"

import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Dash from "../components/Dash"
import Layout from "../components/Layout"
import { getAllLocales, getCurrentLanguagePage } from "../plugins/common"
import { CustomMarkdown } from "../plugins/sanitizehtml"

const simplePageShape_left = "../images/simplePage/simplePageShape_left.webp"
const simplePageShape_right = "../images/simplePage/simplePageShape_right.webp"

const SimplePage = ({ pageContext, location }) => {
  const { data, form, general } = pageContext
  const { title, subtitle, content, meta, localizations, locale } = data
  const aleaToken = general.aleaToken
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)

  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form?.freeQuote,
  }
  // console.log(footerForm)

  return (
    <Layout
      props={pageContext.general}
      meta={meta}
      localizations={getAllLocales(localizations, locale)}
      location={location}
      hideNewsletter={true}
      lang={lang}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="simplePage">
        <div className="container-fluid bg-daffodil text-center header position-relative">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="left position-absolute"
            src={simplePageShape_left}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="right position-absolute"
            src={simplePageShape_right}
            alt=""
            layout="constrained"
          />
          <h1 className="ming">{title}</h1>
        </div>
        <div className="container content card soft-corners soft-shadows">
          <h2 className="subtitle ming">{subtitle}</h2>
          <Dash />
          <CustomMarkdown html={content}></CustomMarkdown>
        </div>
      </div>
    </Layout>
  )
}
export default SimplePage
